require("./bootstrap");

import Vue from 'vue';
import ElementUI from "element-ui";
import Vmodal from "vue-js-modal";
import AsyncComputed from "vue-async-computed";
import Layout from "./components/Layout";
import BasicCalculator from "./components/BasicCalculator";
import ShopifyProducts from "./components/ShopifyProducts";
import ShopifyCustomers from "./components/ShopifyCustomers";
import ShopifyCustomersadmin from "./components/ShopifyCustomersadmin";
import ShopifyTransactions from "./components/ShopifyTransactions";
import ShopifyOrders from "./components/ShopifyOrders";
import DraggableList from "./components/DraggableList";
import DemoModal from "./components/DemoModal";
import "element-ui/lib/theme-chalk/index.css";
import Tasks from "./components/Tasks";
import DemoChart from "./components/DemoChart";
import DarkWeb from "./components/DarkWeb";
import Identity from "./components/Identity";
import InstagramFollowers from "./components/InstagramFollowers";
import InstagramClient from "./components/InstagramClient";
import VueJsonToTable from "./components/VueJsonToTable";

Vue.use(ElementUI);
Vue.use(AsyncComputed);
Vue.use(Vmodal);
//window.Vue.use(VueCharts);

// Global component registration
Vue.component('vue-json-to-table', VueJsonToTable);

// Initialize main app
if (document.getElementById('app')) {
    new Vue({
        el: "#app",
        components: {
            Tasks,
            ShopifyCustomers,
            ShopifyOrders,
            ShopifyProducts,
            ShopifyTransactions,
            BasicCalculator,
            DraggableList,
            DemoModal,
            DemoChart,
            ShopifyCustomersadmin,
            DarkWeb,
            Identity,
            InstagramFollowers,
            InstagramClient
        }
    });
}

// Initialize menu
if (document.getElementById('menu')) {
    new Vue({
        el: "#menu",
        components: {
            Layout
        }
    });
}
