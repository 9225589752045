<template>
    <div>
        <h1>BasicCalculator Component</h1>

        <div class="flex py-8 -mx-2 items-center">
            <div class="px-2">
                <input type="number" v-model="firstNumber" required class="p-2 text-black text-center" />
            </div>
            <div class="px-2">
                <span class="font-bold text-2xl">+</span>
            </div>
            <div class="px-2">
                <input type="number" v-model="secondNumber" required class="p-2 text-black text-center" />
            </div>
            <div class="px-2">
                <button class="p-2 border-2 border-white" :disabled="! readyToSolve" @click="solve" v-if="! solution">
                    Solve
                </button>
                <div class="font-bold text-center text-3xl" v-if="solution">
                    {{ solution }}
                </div>
            </div>
            <div class="px-2">
                <button class="p-2 border-2 border-gray-200" @click="clear" v-if="solution">
                    x Clear
                </button>
            </div>
        </div>

        <div class="py-6">
            <el-select v-model="state" placeholder="Select">
                <el-option
                    v-for="state in states"
                    :key="state"
                    :label="state"
                    :value="state">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                states: [
                    'Alabama',
                    'Alaska',
                    'Arizona',
                    'Arkansas',
                    'California',
                    'Colorado',
                    'Connecticut',
                    'Delaware',
                    'Florida',
                    'Georgia',
                    'Hawaii',
                    'Idaho',
                    'Illinois',
                    'Indiana',
                    'Iowa',
                    'Kansas',
                    'Kentucky',
                    'Louisiana',
                    'Maine',
                    'Maryland',
                    'Massachusetts',
                    'Michigan',
                    'Minnesota',
                    'Mississippi',
                    'Missouri',
                    'Montana',
                    'Nebraska',
                    'Nevada',
                    'New Hampshire',
                    'New Jersey',
                    'New Mexico',
                    'New York',
                    'North Carolina',
                    'North Dakota',
                    'Ohio',
                    'Oklahoma',
                    'Oregon',
                    'Pennsylvania',
                    'Rhode Island',
                    'South Carolina',
                    'South Dakota',
                    'Tennessee',
                    'Texas',
                    'Utah',
                    'Vermont',
                    'Virginia',
                    'Washington',
                    'West Virginia',
                    'Wisconsin',
                    'Wyoming',
                ],
                firstNumber: null,
                secondNumber: null,
                solution: null,
                state: null
            }
        },
        methods: {
            solve() {
                this.solution = parseInt(this.firstNumber) + parseInt(this.secondNumber)
            },
            clear() {
                this.firstNumber = null
                this.secondNumber = null
                this.solution = null
            }
        },
        computed: {
            readyToSolve() {
                return  this.firstNumber &&
                        this.firstNumber.length &&
                        this.secondNumber &&
                        this.secondNumber.length
            }
        }
    }
</script>
