<template>
<div v-if="tabledata" id="app" v-cloak>
<div class="py-1">
  <div class="mb-2 text-left">
    <label for="search">Search</label>
  </div>
  <div class="flex">
    <div class="pr-2 py-2">&gt;</div>
    <input class="p-2 w-full text-white bg-black" type="text" v-model="searchQuery" placeholder="_" >
  </div>
</div>
<div class="py-1 mb-8">
  <div class="mb-2 text-left">
    <label for="filter">Filter</label>
  </div>
  <div class="flex">
    <div class="pr-2 py-2">&gt;</div>
    <select class="p-2" v-model="searchFor" style="max-width: 130px;   -webkit-appearance: none;-moz-appearance: none;text-overflow: '...';background: black;">
      <option selected value="all">*</option>
      <option value="title">Title</option>
      <option value="variant_price">Variant Price</option>
      <option value="option1_name">Option Name</option>
      <option value="vendor">Vendor</option>
      <option value="variant_s_k_u">SKU</option>
    </select>
  </div>
</div>
<table class="text-xs w-full">
    <thead>
    <tr>
        <th class="p-2">Image</th>
        <th class="p-2" @click="sort('title')" >Title</th>
        <th class="p-2" @click="sort('variant_price')" >Price</th>
        <th class="p-2" @click="sort('variant_inventory_qty')">Qty</th>
        <th class="p-2" @click="sort('option1_name')">Options</th>
        <th class="p-2" @click="sort('vendor')">Vendor</th>
        <th class="p-2" @click="sort('variant_s_k_u')">SKU</th>
    </tr>
    </thead>
    <tbody>
      <tr v-for="d in tabledata.data" class="hover:bg-gray-800">
          <td class="border-2 border-white p-2">
            <img :src="d.image_src" :alt="d.image_alt_text" style="max-height: 150px" >
          </td>
          <td class="border-2 border-white p-2">
              {{d.title}}
          </td>
          <td class="border-2 border-white p-2">
               {{d.variant_price }}
          </td>
          <td class="border-2 border-white p-2">
               {{ d.variant_inventory_qty }}
          </td>
          <td class="border-2 border-white p-2">
               <p>{{ d.option1_name }} {{ d.option1_value }}</p><p> {{ d.option2_name }} {{ d.option2_value }}</p> <p>{{ d.option3_name }} {{ d.option3_value }}</p>
          </td>
          <td class="border-2 border-white p-2">
              {{d.vendor}}
          </td>
          <td class="border-2 border-white p-2">
               {{ d.variant_s_k_u }}
          </td>
      </tr>
    </tbody>
</table>

  <p>
  <button @click="firstPage" > First </button>
  <button @click="prevPage" :disabled="cantGoBack"> < </button>
  <button @click="nextPage" :disabled="cantGoForward"> > </button>
  <button @click="lastPage" > Last </button>
  </p>

Page {{tabledata.current_page}} of {{tabledata.last_page}}, {{tabledata.total}}
</div>

</template>

<script>
export default {
name: "ShopifyProducts",
data() {
  return {
  searchQuery: '',
  searchFor: 'all',
  currentSort:'title',
  currentSortDir:'asc',
  pageSize:20,
  currentPage:1
  }
},
methods:{
  sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    } else this.currentSortDir = 'asc';
    this.currentSort = s;
  },
  firstPage:function() {
    this.currentPage = 1;
  },
  lastPage:function() {
    this.currentPage = this.tabledata.last_page;
  },
  nextPage:function() {
    this.currentPage++;
  },
  prevPage:function() {
    if(this.currentPage > 1) this.currentPage--;
  }

},
asyncComputed: {
  async tabledata() {
    let data = await fetch(`/client/shopifyproductsapi?page=${this.currentPage}&per_page=${this.pageSize}&sort_by=${this.currentSort}&sort=${this.currentSortDir}&type=customers&search=${this.searchQuery}&search_for=${this.searchFor}`);
    let result = await data.json();
    return result;
  }
},
computed:{
  cantGoBack() {
    return this.currentPage === 1;
  },
  cantGoForward() {
    return this.currentPage === this.tabledata.last_page;
  },
  sortStr() {
    let s = '';
    if(this.currentSortDir === 'desc') s += '-';
    return s + this.currentSort;
  }
}
}
</script>
