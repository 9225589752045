var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tabledata
    ? _c("div", { attrs: { id: "app" } }, [
        _c("div", { staticClass: "py-1" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "pr-2 py-2" }, [_vm._v(">")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchQuery,
                  expression: "searchQuery"
                }
              ],
              staticClass: "p-2 w-full text-white bg-black",
              attrs: { type: "text", placeholder: "_" },
              domProps: { value: _vm.searchQuery },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchQuery = $event.target.value
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "py-1 mb-8" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "pr-2 py-2" }, [_vm._v(">")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchFor,
                    expression: "searchFor"
                  }
                ],
                staticClass: "p-2",
                staticStyle: {
                  "max-width": "130px",
                  "-webkit-appearance": "none",
                  "-moz-appearance": "none",
                  "text-overflow": "'...'",
                  background: "black"
                },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.searchFor = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { selected: "", value: "all" } }, [
                  _vm._v("*")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "title" } }, [_vm._v("Title")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "variant_price" } }, [
                  _vm._v("Variant Price")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "option1_name" } }, [
                  _vm._v("Option Name")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "vendor" } }, [
                  _vm._v("Vendor")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "variant_s_k_u" } }, [
                  _vm._v("SKU")
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "text-xs w-full" }, [
          _c("thead", [
            _c("tr", [
              _c("th", { staticClass: "p-2" }, [_vm._v("Image")]),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("title")
                    }
                  }
                },
                [_vm._v("Title")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("variant_price")
                    }
                  }
                },
                [_vm._v("Price")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("variant_inventory_qty")
                    }
                  }
                },
                [_vm._v("Qty")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("option1_name")
                    }
                  }
                },
                [_vm._v("Options")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("vendor")
                    }
                  }
                },
                [_vm._v("Vendor")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("variant_s_k_u")
                    }
                  }
                },
                [_vm._v("SKU")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.tabledata.data, function(d) {
              return _c("tr", { staticClass: "hover:bg-gray-800" }, [
                _c("td", { staticClass: "border-2 border-white p-2" }, [
                  _c("img", {
                    staticStyle: { "max-height": "150px" },
                    attrs: { src: d.image_src, alt: d.image_alt_text }
                  })
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "border-2 border-white p-2" }, [
                  _vm._v("\n              " + _vm._s(d.title) + "\n          ")
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "border-2 border-white p-2" }, [
                  _vm._v(
                    "\n               " +
                      _vm._s(d.variant_price) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "border-2 border-white p-2" }, [
                  _vm._v(
                    "\n               " +
                      _vm._s(d.variant_inventory_qty) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "border-2 border-white p-2" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(d.option1_name) + " " + _vm._s(d.option1_value)
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(d.option2_name) +
                        " " +
                        _vm._s(d.option2_value)
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(d.option3_name) + " " + _vm._s(d.option3_value)
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "border-2 border-white p-2" }, [
                  _vm._v("\n              " + _vm._s(d.vendor) + "\n          ")
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "border-2 border-white p-2" }, [
                  _vm._v(
                    "\n               " +
                      _vm._s(d.variant_s_k_u) +
                      "\n          "
                  )
                ])
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _c("button", { on: { click: _vm.firstPage } }, [_vm._v(" First ")]),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { disabled: _vm.cantGoBack },
              on: { click: _vm.prevPage }
            },
            [_vm._v(" < ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { disabled: _vm.cantGoForward },
              on: { click: _vm.nextPage }
            },
            [_vm._v(" > ")]
          ),
          _vm._v(" "),
          _c("button", { on: { click: _vm.lastPage } }, [_vm._v(" Last ")])
        ]),
        _vm._v(
          "\n\nPage " +
            _vm._s(_vm.tabledata.current_page) +
            " of " +
            _vm._s(_vm.tabledata.last_page) +
            ", " +
            _vm._s(_vm.tabledata.total) +
            "\n"
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-2 text-left" }, [
      _c("label", { attrs: { for: "search" } }, [_vm._v("Search")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-2 text-left" }, [
      _c("label", { attrs: { for: "filter" } }, [_vm._v("Filter")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }