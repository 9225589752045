<template>
  <div class="chart">
    <line-chart :chart-data="datacollection" :options="this.options"></line-chart>
  </div>
</template>

<script>
  import LineChart from './LineChart'

  export default {
    components: {
      LineChart
    },
    props: ['data'],
    data () {
      return {
        datacollection: null,
        options: null,
      }
    },
    mounted () {
      this.fillData()
    },
    methods: {
      fillData () {
       this.datacollection = {
          labels: this.data.x,
          datasets:  Object.values(this.data.y)
        },
      this.options = {
      responsive: true,
      maintainAspectRatio: false,
        scales: {
            yAxes: [{
                  ticks: {
                      suggestedMax: 100,
                      fontColor: 'white',
                      fontFamily: "'Monaco', 'Lucida Grande', sans-serif"
                  }
                }],
                xAxes: [{
                  ticks: {
                      display: false,
                      fontColor: 'white',
                      fontFamily: "'Monaco', 'Lucida Grande', sans-serif"
                  }
                }]
            },
        legend: {
            labels: {
                // This more specific font property overrides the global property
                fontColor: 'white',
                fontFamily: "'Monaco', 'Lucida Grande', sans-serif"
            }
        }
        }
      },

    }
  }
</script>

<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
