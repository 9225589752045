var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-main" },
    _vm._l(_vm.arrData, function(row, index) {
      return _c("div", { key: index, staticClass: "row-data m-2 d-flex" }, [
        _c("div", { staticClass: "key p-2 text-capitalize d-inline-block" }, [
          _vm._v("\n            " + _vm._s(_vm.keyTitle(row)) + "\n        ")
        ]),
        _vm._v(" "),
        ["string", "number"].includes(_vm.checkValueType(_vm.data[row]))
          ? _c("div", [
              _c("div", { staticClass: "value p-2 d-inline-block" }, [
                _vm._v(_vm._s(_vm.data[row]))
              ])
            ])
          : _vm.checkValueType(_vm.data[row]) === "array"
          ? _c(
              "div",
              _vm._l(_vm.data[row], function(arrRow, index2) {
                return _c("div", { key: index2, staticClass: "d-flex" }, [
                  _c("div", { staticClass: "mx-2" }, [_vm._v("--")]),
                  _vm._v(" "),
                  ["string", "number"].includes(_vm.checkValueType(arrRow))
                    ? _c("div", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(arrRow) +
                            "\n                "
                        )
                      ])
                    : _c(
                        "div",
                        [_c("vue-json-to-table", { attrs: { data: arrRow } })],
                        1
                      )
                ])
              }),
              0
            )
          : _c(
              "div",
              [_c("vue-json-to-table", { attrs: { data: _vm.data[row] } })],
              1
            )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }