<template>
  <div v-if="tabledata" id="app" v-cloak>
    <div class="py-1">
      <div class="mb-2 text-left">
        <label for="search">Search</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <div class="w-full">
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchEmail"
            v-on:keyup.enter="onEnter"
            placeholder="Email_"
          />
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchPhone"
            v-on:keyup.enter="onEnter"
            placeholder="Phone_"
          />

          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchFirstName"
            v-on:keyup.enter="onEnter"
            placeholder="FirstName_"
          />
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchLastName"
            v-on:keyup.enter="onEnter"
            placeholder="LastName_"
          />
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchCountry"
            v-on:keyup.enter="onEnter"
            placeholder="Country_"
          />
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchState"
            v-on:keyup.enter="onEnter"
            placeholder="State_"
          />
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchCity"
            v-on:keyup.enter="onEnter"
            placeholder="City_"
          />
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchUsername"
            v-on:keyup.enter="onEnter"
            placeholder="Username_"
          />
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchAge"
            v-on:keyup.enter="onEnter"
            placeholder="Age_"
          />
        </div>
      </div>
    </div>
    <vue-json-to-table :data="tabledata"></vue-json-to-table>
    <pre></pre>
  </div>
</template>

<script>
export default {
  name: "Identity",
  data() {
    return {
      searchEmail: "",
      searchPhone: "",
      searchFirstName: "",
      searchLastName: "",
      searchCountry: "",
      searchState: "",
      searchCity: "",
      searchUsername: "",
      searchAge: "",
      searchQuery: ""
    };
  },
  methods: {
    sort: function(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      } else this.currentSortDir = "asc";
      this.currentSort = s;
    },
    firstPage: function() {
      this.currentPage = 1;
    },
    lastPage: function() {
      this.currentPage = 1;
    },
    nextPage: function() {
      this.currentPage++;
    },
    prevPage: function() {
      if (this.currentPage > 1) this.currentPage--;
    },
    onEnter: function() {
      this.searchQuery =
        "?email=" +
        encodeURI(this.searchEmail) +
        "&phone=" +
        encodeURI(this.searchPhone) +
        "&first_name=" +
        encodeURI(this.searchFirstName) +
        "&last_name=" +
        encodeURI(this.searchLastName) +
        "&country=" +
        encodeURI(this.searchCountry) +
        "&state=" +
        encodeURI(this.searchState) +
        "&city=" +
        encodeURI(this.searchCity) +
        "&username=" +
        encodeURI(this.searchUsername) +
        "&age=" +
        encodeURI(this.searchAge);
    }
  },
  asyncComputed: {
    async tabledata() {
      let data = await fetch(`/admin/api/identity${this.searchQuery}`);
      let result = await data.json();
      return result;
    },
    async columns() {
      //let data = await fetch(`/admin/shopifycustomersapi?type=customers`);
      //let result = ["name","email","phone","address","password","username","ip_address","obtained_from","hashed_password"];
      return {
        id: "id",
        name: "name",
        email: "email",
        phone: "phone",
        address: "address",
        password: "password",
        username: "username",
        ip_address: "ip_address",
        obtained_from: "obtained_from",
        hashed_password: "hashed_password"
      };
    }
  },
  computed: {
    cantGoBack() {
      return this.currentPage === 1;
    },
    cantGoForward() {
      return this.currentPage === 1;
    },
    sortStr() {
      let s = "";
      if (this.currentSortDir === "desc") s += "-";
      return s + this.currentSort;
    }
  }
};
</script>
<style scoped>
td.darkweb {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.key {
  background: black !important;
}
</style>
