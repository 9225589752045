var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "bg-black shadow px-1 pt-2 pb-1 border border-white relative "
    },
    [
      _c("div", { staticClass: "flex justify-between" }, [
        _c("p", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editid != _vm.task.id,
              expression: "editid != task.id"
            }
          ],
          staticClass:
            "text-white font-semibold tracking-wide text-xs max-w-full",
          attrs: {
            placeholder: "description",
            contenteditable: "true",
            value: _vm.task.title,
            id: "tasktitle-" + _vm.task.id
          },
          domProps: { textContent: _vm._s(_vm.task.title) },
          on: {
            paste: function($event) {
              return _vm.onPaste($event)
            },
            keyup: function($event) {
              return _vm.checkChanged(_vm.task)
            },
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.$emit("updatePost", _vm.task, $event)
            },
            blur: function($event) {
              return _vm.$emit("updatePost", _vm.task, null)
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex mt-4 justify-between items-center hidden" },
        [
          _c("p", { staticClass: "text-xs" }, [_vm._v("Due Date:")]),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editid != _vm.task.id,
                expression: "editid != task.id"
              }
            ],
            staticClass: "text-xs",
            attrs: {
              placeholder: "due date",
              contenteditable: "true",
              value: _vm.task.title,
              id: "taskdate-" + _vm.task.id
            },
            domProps: { textContent: _vm._s(_vm.task.date) },
            on: {
              paste: function($event) {
                return _vm.onPaste($event)
              },
              keyup: function($event) {
                return _vm.checkChanged(_vm.task)
              },
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("updatePost", _vm.task, $event)
              },
              blur: function($event) {
                return _vm.$emit("updatePost", _vm.task, null)
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid-container" }, [
        _c("div", [
          _c("div", { staticClass: "inline-block mt-4 pr-2" }, [
            _c("p", { staticClass: "text-xs text-gray-600 hidden" }, [
              _vm._v("Number 5")
            ]),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editid != _vm.task.id,
                  expression: "editid != task.id"
                }
              ],
              staticClass: "text-xs",
              attrs: {
                placeholder: "Number 5",
                contenteditable: "true",
                value: _vm.task.assigned,
                id: "taskassigned-" + _vm.task.id
              },
              domProps: { textContent: _vm._s(_vm.task.assigned) },
              on: {
                paste: function($event) {
                  return _vm.onPaste($event)
                },
                keyup: function($event) {
                  return _vm.checkChanged(_vm.task)
                },
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.$emit("updatePost", _vm.task, $event)
                },
                blur: function($event) {
                  return _vm.$emit("updatePost", _vm.task, null)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.task.hours != -1,
                  expression: "task.hours != -1"
                }
              ],
              staticClass: "inline-block mt-4 pr-2"
            },
            [
              _c("p", { staticClass: "text-xs text-gray-600 hidden" }, [
                _vm._v("0")
              ]),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.editid != _vm.task.id,
                    expression: "editid != task.id"
                  }
                ],
                staticClass: "text-xs",
                attrs: {
                  placeholder: "0",
                  contenteditable: "true",
                  value: _vm.task.hours,
                  id: "taskhours-" + _vm.task.id
                },
                domProps: { textContent: _vm._s(_vm.task.hours) },
                on: {
                  paste: function($event) {
                    return _vm.onPaste($event)
                  },
                  keyup: function($event) {
                    return _vm.checkChanged(_vm.task)
                  },
                  keypress: function($event) {
                    return _vm.numbersOnly($event)
                  },
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("updatePost", _vm.task, $event)
                  },
                  blur: function($event) {
                    return _vm.$emit("updatePost", _vm.task, null)
                  }
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "action-button focus:outline-none focus:shadow-outline text-black hover:text-white"
          },
          [
            _c(
              "span",
              {
                staticClass: "inline-block mt-4",
                class: { "text-white": _vm.task.details !== "" },
                staticStyle: { float: "right" },
                attrs: { "aria-label": "Info" }
              },
              [
                _c("i", {
                  staticClass: "fa fa-info-circle mb-2 nodrag",
                  on: {
                    click: function($event) {
                      return _vm.$emit("showDetails", _vm.task)
                    }
                  }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "w-10" }, [
          _c(
            "div",
            { staticClass: "absolute right-0 bottom-0 w-10 h-10 z-0" },
            [
              _c(
                "span",
                {
                  staticClass:
                    " action-button focus:outline-none focus:shadow-outline text-black hover:text-white absolute bottom-0 right-0 w-10 h-10 mr-2 mb-2 z-50",
                  attrs: { "aria-label": "Delete" }
                },
                [
                  _c("i", {
                    staticClass:
                      "nodrag fa fa-times-circle absolute bottom-0 right-0 pb-1 pr-1 z-0",
                    on: {
                      click: function($event) {
                        return _vm.$emit(
                          "on-delete",
                          _vm.task,
                          _vm.indexcolumn,
                          _vm.indexcard
                        )
                      }
                    }
                  })
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }