<template>
  <div class="bg-black shadow px-1 pt-2 pb-1 border border-white relative ">
    <div class="flex justify-between">
      <p
        placeholder="description"
        contenteditable="true"
        @paste="onPaste($event)"
        @keyup="checkChanged(task)"
        @keydown.enter="$emit('updatePost', task, $event)"
        @blur="$emit('updatePost',task, null)"
        :value="task.title"
        v-show="editid != task.id"
        :id="'tasktitle-'+task.id"
        class="text-white font-semibold tracking-wide text-xs max-w-full"
        v-text="task.title"
      ></p>
    </div>
    <div class="flex mt-4 justify-between items-center hidden">
      <p class="text-xs">Due Date:</p>
      <span
        placeholder="due date"
        contenteditable="true"
        @paste="onPaste($event)"
        @keyup="checkChanged(task)"
        @keydown.enter="$emit('updatePost', task, $event)"
        @blur="$emit('updatePost',task, null)"
        :value="task.title"
        v-show="editid != task.id"
        :id="'taskdate-'+task.id"
        class="text-xs"
        v-text="task.date"
      ></span>
    </div>
    <div class="grid-container">
      <div>
        <div class="inline-block mt-4 pr-2">
          <p class="text-xs text-gray-600 hidden">Number 5</p>
          <span
            placeholder="Number 5"
            contenteditable="true"
            @paste="onPaste($event)"
            @keyup="checkChanged(task)"
            @keydown.enter="$emit('updatePost', task, $event)"
            @blur="$emit('updatePost',task, null)"
            :value="task.assigned"
            v-show="editid != task.id"
            :id="'taskassigned-'+task.id"
            class="text-xs"
            v-text="task.assigned"
          ></span>
        </div>
      </div>
      <div>
        <div class="inline-block mt-4 pr-2" v-show="task.hours != -1">
          <p class="text-xs text-gray-600 hidden">0</p>
          <span
            placeholder="0"
            contenteditable="true"
            @paste="onPaste($event)"
            @keyup="checkChanged(task)"
            @keypress="numbersOnly($event)"
            @keydown.enter="$emit('updatePost', task, $event)"
            @blur="$emit('updatePost',task, null)"
            :value="task.hours"
            v-show="editid != task.id"
            :id="'taskhours-'+task.id"
            class="text-xs"
            v-text="task.hours"
          ></span>
        </div>
      </div>
      <div class="action-button focus:outline-none focus:shadow-outline text-black hover:text-white">
        <span 
            style="float: right;"
            aria-label="Info"
            class="inline-block mt-4"
            :class="{ 'text-white' : task.details  !==  ''}"
          >
        <i @click="$emit('showDetails', task)" class="fa fa-info-circle mb-2 nodrag"></i>
        </span>
      </div>
      <div class="w-10">
        <div class="absolute right-0 bottom-0 w-10 h-10 z-0">
          <span

            aria-label="Delete"
            class=" action-button focus:outline-none focus:shadow-outline text-black hover:text-white absolute bottom-0 right-0 w-10 h-10 mr-2 mb-2 z-50"

          >
            <i @click="$emit('on-delete', task, indexcolumn, indexcard )" class="nodrag fa fa-times-circle absolute bottom-0 right-0 pb-1 pr-1 z-0"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      changed: 0
    };
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    editid: {
      type: Number
    },
    indexcard: {
      type: Number
    },
    indexcolumn: {
      type: Number
    }
  },
  computed: {},
  methods: {
    checkChanged(task) {
      if (
        task.title !==
          document.getElementById("tasktitle-" + task.id).textContent ||
        task.date !==
          document.getElementById("taskdate-" + task.id).textContent ||
        task.assigned !==
          document.getElementById("taskassigned-" + task.id).textContent ||
        task.hours !==
          document.getElementById("taskhours-" + task.id).textContent
      ) {
        task.changed = 1;
      } else {
        task.changed = 0;
      }
    },

    numbersOnly(evt) {
    evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();;
          } else {
            return true;
          }
    },
    onPaste(e) {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
    }

  }
};
</script>
<style>
.grid-container {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-template-rows: 1fr;
  gap: 1px 0px;
  grid-template-areas:
    ". . . .";
}
</style>