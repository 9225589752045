var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tabledata
    ? _c("div", { attrs: { id: "app" } }, [
        _c("div", { staticClass: "py-1" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "pr-2 py-2" }, [_vm._v(">")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchQuery,
                  expression: "searchQuery"
                }
              ],
              staticClass: "p-2 w-full text-white bg-black",
              attrs: { type: "text", placeholder: "_" },
              domProps: { value: _vm.searchQuery },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchQuery = $event.target.value
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "py-1 mb-8" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "pr-2 py-2" }, [_vm._v(">")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchFor,
                    expression: "searchFor"
                  }
                ],
                staticClass: "p-2",
                staticStyle: {
                  "max-width": "130px",
                  "-webkit-appearance": "none",
                  "-moz-appearance": "none",
                  "text-overflow": "'...'",
                  background: "black"
                },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.searchFor = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { selected: "", value: "all" } }, [
                  _vm._v("*")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "name" } }, [
                  _vm._v("Account Name")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "billing_name" } }, [
                  _vm._v("Billing Name")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "shipping_name" } }, [
                  _vm._v("Shipping Name")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "email" } }, [_vm._v("Email")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "order_id" } }, [
                  _vm._v("Order ID")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "discount_code" } }, [
                  _vm._v("Discount Code")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "billing_address1" } }, [
                  _vm._v("Billing Address 1")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "billing_city" } }, [
                  _vm._v("Billing City")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "billing_zip" } }, [
                  _vm._v("Billing ZIP")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "billing_country" } }, [
                  _vm._v("Billing Country")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "billing_phone" } }, [
                  _vm._v("Billing Phone")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "shipping_address1" } }, [
                  _vm._v("Shipping Address 1")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "shipping_city" } }, [
                  _vm._v("Shipping City")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "shipping_zip" } }, [
                  _vm._v("Shipping ZIP")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "shipping_country" } }, [
                  _vm._v("Shipping Country")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "shipping_phone" } }, [
                  _vm._v("Shipping Phone")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "lineitem_name" } }, [
                  _vm._v("Line Item")
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "text-xs w-full" }, [
          _c("thead", [
            _c("tr", [
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("order_id")
                    }
                  }
                },
                [_vm._v("Order ID")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("paid_at")
                    }
                  }
                },
                [_vm._v("Paid At")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  staticStyle: { "min-width": "150px" },
                  on: {
                    click: function($event) {
                      return _vm.sort("total")
                    }
                  }
                },
                [_vm._v("Total")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("discount_amount")
                    }
                  }
                },
                [_vm._v("Discount")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("billing_name")
                    }
                  }
                },
                [_vm._v("Billing")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("shipping_name")
                    }
                  }
                },
                [_vm._v("Shipping")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  staticStyle: { "max-width": "150px" },
                  on: {
                    click: function($event) {
                      return _vm.sort("products")
                    }
                  }
                },
                [_vm._v("Product")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "p-2",
                  on: {
                    click: function($event) {
                      return _vm.sort("visit_source_description")
                    }
                  }
                },
                [_vm._v("Attribution")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.tabledata.data, function(d) {
              return _c("tr", [
                _c("td", { staticClass: "p-2 border-2 border-white" }, [
                  _c("div", [
                    _vm._v("\n            Order ID:\n            "),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(d.order_id))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-2" }, [
                    _vm._v("\n            Email:\n            "),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(d.email))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "p-2 border-2 border-white" }, [
                  d.financial_status === "paid"
                    ? _c("div", [
                        _vm._v("\n            Paid:\n            "),
                        _c("div", [_vm._v(_vm._s(d.paid_at))])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-4" }, [
                    d.fulfillment_status === "fulfilled"
                      ? _c("div", [
                          _vm._v("\n              Fulfilled:\n              "),
                          _c("div", [_vm._v(_vm._s(d.fulfilled_at))])
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "p-2 border-2 border-white" }, [
                  _c("div", [
                    _vm._v("\n            subtotal:\n            "),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(d.subtotal))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("\n            shipping:\n            "),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(d.shipping))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("\n            tax:\n            "),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(d.taxes))
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._m(2, true),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("\n            total:\n            "),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(d.total))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "p-2 border-2 border-white" }, [
                  d.discount_code
                    ? _c("div", [
                        _c("div", [
                          _vm._v("\n              CODE:\n              "),
                          _c("strong", [_vm._v(_vm._s(d.discount_code))])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("\n              AMOUNT:\n              "),
                          _c("strong", [_vm._v(_vm._s(d.discount_amount))])
                        ])
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "p-2 border-2 border-white" }, [
                  _c("div", [_vm._v(_vm._s(d.billing_name))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(d.billing_street))]),
                  _vm._v(" "),
                  d.billing_city
                    ? _c("div", [
                        _c("div", [
                          _vm._v(
                            _vm._s(d.billing_city) +
                              ", " +
                              _vm._s(d.billing_province)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(d.billing_zip))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(d.billing_country))])
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "p-2 border-2 border-white" }, [
                  _c("div", [_vm._v(_vm._s(d.shipping_name))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(d.shipping_street))]),
                  _vm._v(" "),
                  d.shipping_city
                    ? _c("div", [
                        _c("div", [
                          _vm._v(
                            _vm._s(d.shipping_city) +
                              ", " +
                              _vm._s(d.shipping_province)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(d.shipping_zip))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(d.shipping_country))])
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "p-2 border-2 border-white",
                    staticStyle: { "max-width": "150px" }
                  },
                  [_c("div", { domProps: { innerHTML: _vm._s(d.products) } })]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "p-2 border-2 border-white",
                    staticStyle: { "max-width": "150px" }
                  },
                  [_c("div", [_vm._v(_vm._s(d.visit_source_description))])]
                )
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _c("button", { on: { click: _vm.firstPage } }, [_vm._v("First")]),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { disabled: _vm.cantGoBack },
              on: { click: _vm.prevPage }
            },
            [_vm._v("<")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { disabled: _vm.cantGoForward },
              on: { click: _vm.nextPage }
            },
            [_vm._v(">")]
          ),
          _vm._v(" "),
          _c("button", { on: { click: _vm.lastPage } }, [_vm._v("Last")])
        ]),
        _vm._v(
          "\n  Page " +
            _vm._s(_vm.tabledata.current_page) +
            " of " +
            _vm._s(_vm.tabledata.last_page) +
            ", " +
            _vm._s(_vm.tabledata.total) +
            "\n"
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-2 text-left" }, [
      _c("label", { attrs: { for: "search" } }, [_vm._v("Search")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-2 text-left" }, [
      _c("label", { attrs: { for: "filter" } }, [_vm._v("Filter")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "font-bold" }, [_vm._v("--------------")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }