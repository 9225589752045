<template>
    <div>
      <div  class="w-full lg:hidden">
        <div class="px-4 py-4 justify-end items-center shadow lg:shadow-none lg:w-4/5 xl:w-2/3 lg:mx-auto">
            <div class="flex-1 flex">

            </div>
            <div class="lg:hidden">
                <i  @click="toggle"
                    class="fa"
                    :class="{'fa-bars': isClosed, 'fa-times': isOpen}"></i>
            </div>
        </div>
        <div class="p-4 lg:block lg:bg-dg-purple lg:text-white" :class="{hidden: isClosed}">


            <ul class="text-white-600 lg:flex lg:w-4/5 xl:w-2/3 lg:m-auto">
                <li class="lg:ml-4" v-for="link in links">

                    <a v-bind:target="link.target" v-if="link.href" :href="link.href"
                                    class="block p-2 flex items-center lg:mr-4 lg:text-white"
                                    :class="linkClass(link)">
                        <div class="xs:invisible sm:invisible md:invisible lg:visible pr-2">
                        </div>
                        <div class="flex-1">{{ link.name }}</div>
                        <div class="lg:invisible">

                        </div>
                    </a>
                    <div v-if="link.href === ''" class="block flex items-center lg:mr-4 lg:text-white"></br>
                    {{ link.name }}</div>
                </li>
            </ul>
            <!--
            <div class="flex items-left justify-left py-4">
                <div class="pr-2">
                    <i class="fa fa-user-circle fa-2x text-gray-400"></i>
                </div>
                <div class="text-xs">
                    <div class="font-bold text-white-900 font-bold">{{name}}</div>
                    <div class="text-gray-400">{{account}}</div>
                </div>
            </div>
            -->

         </div>
        </div>
        <ul class="my-6 hidden lg:block" style="min-width: 190px;">
        <li v-for="link in links">
          <a  v-bind:target="link.target" v-if="link.href" :href="link.href" class="block py-3 px-4">{{link.name}}</a>
          <div v-if="link.href === ''" class="pt-5 px-3 block flex items-center lg:mr-4 lg:text-white">
          {{ link.name }}</div>
          </li>
        </ul>
    </div>


</template>


<script>
    const STATE_OPEN = 'open'
    const STATE_CLOSED = 'closed'

    export default {
        name: "SiteMenu",
        data() {
            return {
                name: users_name,
                account: client_account,
                state: STATE_CLOSED,
                links: items
            }
        },
        methods: {
            toggle() {
                switch (this.state) {
                    case STATE_OPEN:
                        this.state = STATE_CLOSED
                        break;

                    case STATE_CLOSED:
                        this.state = STATE_OPEN
                        break;
                }
            },
            linkClass(link) {
                return {
                  'text-purple-500': ''
                }
            }
        },
        computed: {
            isOpen() {
                return this.state === STATE_OPEN
            },
            isClosed() {
                return ! this.isOpen
            },
        }
    }
</script>
