<template>
  <div v-if="tabledata" id="app" v-cloak>
    <div class="py-1">
      <div class="mb-2 text-left">
        <label for="search">Search</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <input
          class="p-2 w-full text-white bg-black"
          type="text"
          v-model="searchQuery"
          placeholder="_"
        />
      </div>
    </div>
    <div class="py-1 mb-8">
      <div class="mb-2 text-left">
        <label for="filter">Filter</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <select
          class="p-2"
          v-model="searchFor"
          style="max-width: 130px;   -webkit-appearance: none;-moz-appearance: none;text-overflow: '...';background: black;"
        >
          <option selected value="all">*</option>
          <option value="name">Account Name</option>
          <option value="billing_name">Billing Name</option>
          <option value="shipping_name">Shipping Name</option>
          <option value="email">Email</option>
          <option value="order_id">Order ID</option>
          <option value="discount_code">Discount Code</option>
          <option value="billing_address1">Billing Address 1</option>
          <option value="billing_city">Billing City</option>
          <option value="billing_zip">Billing ZIP</option>
          <option value="billing_country">Billing Country</option>
          <option value="billing_phone">Billing Phone</option>
          <option value="shipping_address1">Shipping Address 1</option>
          <option value="shipping_city">Shipping City</option>
          <option value="shipping_zip">Shipping ZIP</option>
          <option value="shipping_country">Shipping Country</option>
          <option value="shipping_phone">Shipping Phone</option>
          <option value="lineitem_name">Line Item</option>
        </select>
      </div>
    </div>
    <table class="text-xs w-full">
      <thead>
        <tr>
          <th class="p-2" @click="sort('order_id')">Order ID</th>
          <th class="p-2" @click="sort('paid_at')">Paid At</th>
          <th class="p-2" @click="sort('total')" style="min-width: 150px;">Total</th>
          <th class="p-2" @click="sort('discount_amount')">Discount</th>
          <th class="p-2" @click="sort('billing_name')">Billing</th>
          <th class="p-2" @click="sort('shipping_name')">Shipping</th>
          <th class="p-2" style="max-width: 150px;" @click="sort('products')">Product</th>
          <th class="p-2" @click="sort('visit_source_description')">Attribution</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="d in tabledata.data">
          <td class="p-2 border-2 border-white">
            <div>
              Order ID:
              <span class="font-bold">{{ d.order_id }}</span>
            </div>
            <div class="mt-2">
              Email:
              <span class="font-bold">{{ d.email }}</span>
            </div>
          </td>
          <td class="p-2 border-2 border-white">
            <div v-if="d.financial_status === 'paid'">
              Paid:
              <div>{{ d.paid_at }}</div>
            </div>
            <div class="mt-4">
              <div v-if="d.fulfillment_status === 'fulfilled'">
                Fulfilled:
                <div>{{ d.fulfilled_at }}</div>
              </div>
            </div>
          </td>
          <td class="p-2 border-2 border-white">
            <div>
              subtotal:
              <span class="font-bold">{{ d.subtotal }}</span>
            </div>
            <div>
              shipping:
              <span class="font-bold">{{ d.shipping }}</span>
            </div>
            <div>
              tax:
              <span class="font-bold">{{ d.taxes }}</span>
            </div>
            <div>
              <span class="font-bold">--------------</span>
            </div>
            <div>
              total:
              <span class="font-bold">{{ d.total }}</span>
            </div>
          </td>
          <td class="p-2 border-2 border-white">
            <div v-if="d.discount_code">
              <div>
                CODE:
                <strong>{{ d.discount_code }}</strong>
              </div>
              <div>
                AMOUNT:
                <strong>{{ d.discount_amount }}</strong>
              </div>
            </div>
          </td>
          <td class="p-2 border-2 border-white">
            <div>{{ d.billing_name }}</div>
            <div>{{ d.billing_street }}</div>
            <div v-if="d.billing_city">
              <div>{{ d.billing_city }}, {{ d.billing_province }}</div>
            </div>
            <div>{{ d.billing_zip }}</div>
            <div>{{ d.billing_country }}</div>
          </td>
          <td class="p-2 border-2 border-white">
            <div>{{ d.shipping_name }}</div>
            <div>{{ d.shipping_street }}</div>
            <div v-if="d.shipping_city">
              <div>{{ d.shipping_city }}, {{ d.shipping_province }}</div>
            </div>
            <div>{{ d.shipping_zip }}</div>
            <div>{{ d.shipping_country }}</div>
          </td>
          <td class="p-2 border-2 border-white" style="max-width: 150px;">
            <div v-html="d.products"></div>
          </td>
          <td class="p-2 border-2 border-white" style="max-width: 150px;">
            <div>{{ d.visit_source_description }}</div>
          </td>
        </tr>
      </tbody>
    </table>

    <p>
      <button @click="firstPage">First</button>
      <button @click="prevPage" :disabled="cantGoBack"><</button>
      <button @click="nextPage" :disabled="cantGoForward">></button>
      <button @click="lastPage">Last</button>
    </p>
    Page {{tabledata.current_page}} of {{tabledata.last_page}}, {{tabledata.total}}
  </div>
</template>

<script>
export default {
  name: "ShopifyOrders",
  data() {
    return {
      searchQuery: "",
      searchFor: "all",
      currentSort: "paid_at",
      currentSortDir: "desc",
      pageSize: 20,
      currentPage: 1
    };
  },
  methods: {
    sort: function(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      } else this.currentSortDir = "asc";
      this.currentSort = s;
    },
    firstPage: function() {
      this.currentPage = 1;
    },
    lastPage: function() {
      this.currentPage = this.tabledata.last_page;
    },
    nextPage: function() {
      this.currentPage++;
    },
    prevPage: function() {
      if (this.currentPage > 1) this.currentPage--;
    }
  },
  asyncComputed: {
    async tabledata() {
      let data = await fetch(
        `/client/shopifyordersapi?page=${this.currentPage}&per_page=${this.pageSize}&sort_by=${this.currentSort}&sort=${this.currentSortDir}&type=customers&search=${this.searchQuery}&search_for=${this.searchFor}`
      );
      let result = await data.json();
      return result;
    }
  },
  computed: {
    cantGoBack() {
      return this.currentPage === 1;
    },
    cantGoForward() {
      return this.currentPage === this.tabledata.last_page;
    },
    sortStr() {
      let s = "";
      if (this.currentSortDir === "desc") s += "-";
      return s + this.currentSort;
    }
  }
};
</script>
