var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("BasicCalculator Component")]),
    _vm._v(" "),
    _c("div", { staticClass: "flex py-8 -mx-2 items-center" }, [
      _c("div", { staticClass: "px-2" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.firstNumber,
              expression: "firstNumber"
            }
          ],
          staticClass: "p-2 text-black text-center",
          attrs: { type: "number", required: "" },
          domProps: { value: _vm.firstNumber },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.firstNumber = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "px-2" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.secondNumber,
              expression: "secondNumber"
            }
          ],
          staticClass: "p-2 text-black text-center",
          attrs: { type: "number", required: "" },
          domProps: { value: _vm.secondNumber },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.secondNumber = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "px-2" }, [
        !_vm.solution
          ? _c(
              "button",
              {
                staticClass: "p-2 border-2 border-white",
                attrs: { disabled: !_vm.readyToSolve },
                on: { click: _vm.solve }
              },
              [_vm._v("\n                Solve\n            ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.solution
          ? _c("div", { staticClass: "font-bold text-center text-3xl" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.solution) + "\n            "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "px-2" }, [
        _vm.solution
          ? _c(
              "button",
              {
                staticClass: "p-2 border-2 border-gray-200",
                on: { click: _vm.clear }
              },
              [_vm._v("\n                x Clear\n            ")]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "py-6" },
      [
        _c(
          "el-select",
          {
            attrs: { placeholder: "Select" },
            model: {
              value: _vm.state,
              callback: function($$v) {
                _vm.state = $$v
              },
              expression: "state"
            }
          },
          _vm._l(_vm.states, function(state) {
            return _c("el-option", {
              key: state,
              attrs: { label: state, value: state }
            })
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "px-2" }, [
      _c("span", { staticClass: "font-bold text-2xl" }, [_vm._v("+")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }