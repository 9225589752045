var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: { name: "details", adaptive: true },
          on: { "before-close": _vm.beforeClose }
        },
        [
          _c("div", { staticClass: "bg-black border-2 border-white h-full" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.detailsobject.details,
                  expression: "detailsobject.details"
                }
              ],
              staticClass: "bg-black border-none h-full w-full resize-none",
              domProps: { value: _vm.detailsobject.details },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.detailsobject, "details", $event.target.value)
                }
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bg-black", attrs: { id: "app" } }, [
        _c("div", { staticClass: "flex justify-left" }, [
          _c(
            "div",
            { staticClass: "min-h-screen flex overflow-x-scroll py-12" },
            _vm._l(_vm.columns, function(column, index) {
              return _c(
                "div",
                {
                  key: column.title,
                  staticClass:
                    "border-white border-solid border-2 bg-black px-1 py-1 column-width mr-4 flex flex-col"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-white font-semibold font-sans tracking-wide text-sm"
                    },
                    [_vm._v(_vm._s(column.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "draggable",
                    {
                      attrs: {
                        id: "column-" + index,
                        filter: ".nodrag",
                        list: column.tasks,
                        animation: 200,
                        "ghost-class": "ghost-card",
                        group: "tasks"
                      },
                      on: {
                        end: _vm.sendalert,
                        change: function($event) {
                          return _vm.updateItemOrder(column)
                        }
                      }
                    },
                    _vm._l(column.tasks, function(task, index2) {
                      return _c("task-card", {
                        key: task.id,
                        staticClass: "mt-3 cursor-move z-0",
                        attrs: {
                          task: task,
                          indexcard: index2,
                          indexcolumn: index,
                          editid: _vm.editid,
                          column: column
                        },
                        on: {
                          showDetails: _vm.showDetails,
                          updatePost: _vm.updatePost,
                          "on-delete": _vm.onDelete
                        }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "flex-1 relative",
                      on: {
                        click: function($event) {
                          return _vm.addnew(column)
                        }
                      }
                    },
                    [
                      _c("a", { staticClass: "text-white absolute top-0" }, [
                        _vm._v("+")
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }