<template>
<div>
  <modal name="details"
  :adaptive="true"
  @before-close="beforeClose">
    <div class="bg-black border-2 border-white h-full">
        <textarea v-text="details" class="bg-black border-none h-full w-full resize-none"></textarea>
    </div>
  </modal>
  <button @click="showDetails()">Details</button>
</div>
</template>

<script>

    export default {
        components: {
        },
        name: "DemoModal",
        data() {
            return {
            details: "test",
            counter: 0,
            }
        },
        methods: {
        showDetails () {
          this.$modal.show('details');
        },
        hideDetails () {
          this.$modal.hide('details');
        },
        beforeClose () {

        }
      },
    }
</script>
