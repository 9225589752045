var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Draggable List")]),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "mt-8",
          model: {
            value: _vm.states,
            callback: function($$v) {
              _vm.states = $$v
            },
            expression: "states"
          }
        },
        _vm._l(_vm.states, function(state, index) {
          return _c(
            "div",
            {
              key: "state-" + index,
              staticClass:
                "p-2 my-2 font-bold uppercase border border-white hover:bg-gray-900 hover:cursor-pointer"
            },
            [_vm._v("\n            " + _vm._s(state) + "\n        ")]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }