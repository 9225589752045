<template>
<div>
<modal name="details"
:adaptive="true"
@before-close="beforeClose">
  <div class="bg-black border-2 border-white h-full">
      <textarea v-model="detailsobject.details" class="bg-black border-none h-full w-full resize-none"></textarea>
  </div>
</modal>
  <div id="app" class="bg-black">


    <div class="flex justify-left">
      <div
      class="min-h-screen flex overflow-x-scroll py-12">
        <div
          v-for="(column, index) in columns"
          :key="column.title"
          class="border-white border-solid border-2 bg-black px-1 py-1 column-width mr-4 flex flex-col"
        >
          <p class="text-white font-semibold font-sans tracking-wide text-sm">{{column.title}}</p>

          <draggable
            @end="sendalert"
            :id="'column-'+index"
            @change="updateItemOrder(column)"
            filter=".nodrag"
            class=""
            :list="column.tasks"
            :animation="200"
            ghost-class="ghost-card"
            group="tasks"
          >
            <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
            <task-card
              v-for="(task, index2) in column.tasks"
              :key="task.id"
              :task="task"
              v-bind:indexcard="index2"
              v-bind:indexcolumn="index"
              v-bind:editid="editid"
              v-bind:column="column"
              class="mt-3 cursor-move z-0"
              @showDetails="showDetails"
              @updatePost="updatePost"
              @on-delete="onDelete"
            ></task-card>
            <!-- </transition-group> -->
          </draggable>
          <button @click="addnew(column)" class="flex-1 relative">
            <a class="text-white absolute top-0">+</a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import TaskCard from "./TaskCard";
import draggable from "vuedraggable";
import axios from "axios";

export default {
  name: "Tasks",
  components: {
    TaskCard,
    draggable
  },
  props: {
    view: {
      type: String,
      default:'client',
    },
    clientid: {
      type: String,
      default:"0",
    },
    adminid: {
      type: String,
      default:"0",
    }
  },
  data() {
    return {
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      detailsobject: Object,
      editid: -1,
      debounce: false,
      lastid: 4,
      columns: [],
      apiurl: {
      admin: {
        gettasks: "/admin/trackerapi/gettasks/"+this.clientid+"/"+this.adminid,
        updatetasks: "/admin/trackerapi/updatetasks/"+this.clientid+"/"+this.adminid,
        },
        client: {
          gettasks: "/client/trackerapi/gettasks",
          updatetasks: "/client/trackerapi/updatetasks",
        },
      },
    };
  },
    mounted() {
        this.fetchTasks()
        this.listenForUpdates()
    },
  methods: {
    onDelete(task, indexcolumn, indexcard) {
    const data = {
       update: "delete",
       id: task.id,

     };
    axios
      .post(this.apiurl[this.view].updatetasks, data)
      .then(function(response) {
      })
      .catch(function(error) {
        console.log(error);
      });

      this.$delete(this.columns[indexcolumn]['tasks'], indexcard);

    },
    updatePost(task, $event) {
      this.editOffset = -1;
      if ($event != null) {
        $event.preventDefault();
        $event.target.blur();
      }
      if (task.changed === 1) {
        task.title = document.getElementById("tasktitle-" + task.id).textContent;
        task.date = document.getElementById("taskdate-" + task.id).textContent;
        task.assigned = document.getElementById(
          "taskassigned-" + task.id
        ).textContent;
        task.hours = document.getElementById(
          "taskhours-" + task.id
        ).textContent;

        task.changed = 0;
      }
       const headers = {
      };
       const data = {
        update: "task",
        id: task.id,
        title: task.title,
        completed: task.completed,
        assigned: task.assigned,
        hours: task.hours,
        date: task.date

      };
      axios
        .post(this.apiurl[this.view].updatetasks, data, {
          headers: headers
        })
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    addnew(column) {

    const data = {
       update: "new",
       column: column.id,

     };
    axios
      .post(this.apiurl[this.view].updatetasks, data)
      .then(function(response) {
          column["tasks"].push({
          id: response.data.ID,
          order: 999,
          changed: 0,
          hours: response.data.pt,
          title: "",
          date: "",
          assigned: "",
          details: "",

          });
        console.log(response);
      })
      .catch(function(error) {
        console.log(error);
      });


    },
    sendalert: function (evt){
      let fromColumn = evt.from.id.split("-")[1];
      let toColumn = evt.to.id.split("-")[1];
      let oldOrder = evt.oldDraggableIndex;
      let newOrder = evt.newDraggableIndex;
      let task = this.columns[toColumn].tasks[newOrder];
      let updateItems = []
      if (fromColumn === toColumn && oldOrder === newOrder){
        return;
      } else if(fromColumn !== toColumn){
        this.updateItemOrder(this.columns[fromColumn]);
        this.updateItemOrder(this.columns[toColumn]);
        updateItems = updateItems.concat(this.columns[fromColumn].tasks);
        updateItems = updateItems.concat(this.columns[toColumn].tasks);
        task.column=parseInt(toColumn)+1;
      } else if(oldOrder !== newOrder){
        this.updateItemOrder(this.columns[toColumn]);
        updateItems = updateItems.concat(this.columns[toColumn].tasks);
      }


        const data = {
           update: "sort",
           column: toColumn,
           columndata: JSON.parse(JSON.stringify(updateItems)),
         };
        axios
          .post(this.apiurl[this.view].updatetasks, data)
          .then(function(response) {
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });


    },
    updateItemOrder(column) {

    Object.keys(column.tasks).forEach(function(k) {
      column.tasks[k].order = Number(k);
    });




    },
    showDetails (task) {
      this.detailsobject = task;
      this.$modal.show('details');
    },
    hideDetails () {
      this.$modal.hide('details');
    },
    beforeClose () {
    const headers = {
    };
    const data = {
      update: "details",
      id: this.detailsobject.id,
      details: this.detailsobject.details
    };
      axios
      .post(this.apiurl[this.view].updatetasks, data, {
        headers: headers
      })
      .then(function(response) {
        console.log(response);
      })
      .catch(function(error) {
        console.log(error);
      });
    },
      async fetchTasks() {
        try {
            const response = await axios.get(this.apiurl[this.view].gettasks)

            this.columns = response.data
        } catch (e) {
            console.log(e)
        }
      },
      listenForUpdates() {
        let channel = `client.${this.clientid}`

        if (this.adminid !== "0") {
        channel = `admin.${this.adminid}`
        } else if (this.clientid === "0")  {
          return
        }

        console.log(`Initializing client connection...`)
        console.log(`Listening on channel ${channel}`)
        window.Echo
            .private(channel)
            .listen(`.App\\Events\\ClientBoardUpdated`, (e) => {
                console.log('update received', e)
                this.fetchTasks()
            })
      }
  }
};
</script>
<style>
[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  /* display: block;  For Firefox */
}

p[contenteditable=true] {

}
</style>
<style scoped>

.column-width {
  min-width: 280px;
  width: 290px;
}
.ghost-card {
  opacity: 0.5;
  background: #000;
  border: 1px dashed #fff;
}
</style>
