import { render, staticRenderFns } from "./Tasks.vue?vue&type=template&id=43ab059a&scoped=true&"
import script from "./Tasks.vue?vue&type=script&lang=js&"
export * from "./Tasks.vue?vue&type=script&lang=js&"
import style0 from "./Tasks.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Tasks.vue?vue&type=style&index=1&id=43ab059a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43ab059a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/number5.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43ab059a')) {
      api.createRecord('43ab059a', component.options)
    } else {
      api.reload('43ab059a', component.options)
    }
    module.hot.accept("./Tasks.vue?vue&type=template&id=43ab059a&scoped=true&", function () {
      api.rerender('43ab059a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/Tasks.vue"
export default component.exports