<template>
  <div v-if="tabledata" id="app" v-cloak>
    <div class="py-1">
      <div class="mb-2 text-left">
        <label for="search">Search</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <input
          class="p-2 w-full text-white bg-black"
          type="text"
          v-model="searchQuery"
          placeholder="_"
        />
      </div>
    </div>
    <div class="py-1 mb-8">
      <div class="mb-2 text-left">
        <label for="filter">Filter</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <select
          class="p-2"
          v-model="searchFor"
          style="max-width: 130px;   -webkit-appearance: none;-moz-appearance: none;text-overflow: '...';background: black;"
        >
          <option selected value="all">*</option>
          <option value="order">Order Number</option>
          <option value="gateway">Gateway</option>
          <option value="created_at">Date</option>
          <option value="status">Status</option>
          <option value="amount">Amount</option>
          <option value="currency">Currency</option>
        </select>
      </div>
    </div>
    <table class="text-xs w-full">
      <thead>
        <tr>
          <th class="p-2" @click="sort('order')">Order Number</th>
          <th class="p-2" @click="sort('kind')">Type</th>
          <th class="p-2" @click="sort('gateway')">Gateway</th>
          <th class="p-2" @click="sort('created_at')">Date</th>
          <th class="p-2" @click="sort('status')">Status</th>
          <th class="p-2" @click="sort('amount')">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="d in tabledata.data" class="hover:bg-gray-800">
          <td class="border-2 border-white p-2">{{ d.order }}</td>
          <td class="border-2 border-white p-2">{{d.kind }}</td>
          <td class="border-2 border-white p-2">
            <p>{{ d.gateway }}</p>
            <p v-if="d.card_type">{{ d.card_type }}</p>
          </td>
          <td class="border-2 border-white p-2">{{ d.created_at }}</td>
          <td class="border-2 border-white p-2">{{ d.status}}</td>
          <td class="border-2 border-white p-2">{{ d.amount }} {{ d.currency }}</td>
        </tr>
      </tbody>
    </table>

    <p>
      <button @click="firstPage">First</button>
      <button @click="prevPage" :disabled="cantGoBack"><</button>
      <button @click="nextPage" :disabled="cantGoForward">></button>
      <button @click="lastPage">Last</button>
    </p>
    Page {{tabledata.current_page}} of {{tabledata.last_page}}, {{tabledata.total}}
  </div>
</template>

<script>
export default {
  name: "ShopifyTransactions",
  data() {
    return {
      searchQuery: "",
      searchFor: "all",
      currentSort: "order",
      currentSortDir: "desc",
      pageSize: 20,
      currentPage: 1
    };
  },
  methods: {
    sort: function(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      } else this.currentSortDir = "asc";
      this.currentSort = s;
    },
    firstPage: function() {
      this.currentPage = 1;
    },
    lastPage: function() {
      this.currentPage = this.tabledata.last_page;
    },
    nextPage: function() {
      this.currentPage++;
    },
    prevPage: function() {
      if (this.currentPage > 1) this.currentPage--;
    }
  },
  asyncComputed: {
    async tabledata() {
      let data = await fetch(
        `/client/shopifytransactionsapi?page=${this.currentPage}&per_page=${this.pageSize}&sort_by=${this.currentSort}&sort=${this.currentSortDir}&type=customers&search=${this.searchQuery}&search_for=${this.searchFor}`
      );
      let result = await data.json();
      return result;
    }
  },
  computed: {
    cantGoBack() {
      return this.currentPage === 1;
    },
    cantGoForward() {
      return this.currentPage === this.tabledata.last_page;
    },
    sortStr() {
      let s = "";
      if (this.currentSortDir === "desc") s += "-";
      return s + this.currentSort;
    }
  }
};
</script>
