<template>

<div>
    <site-menu />
</div>
</template>

<script>
    import SiteMenu from "./SiteMenu";

    export default {
        components: {
            SiteMenu,
        },
    }
</script>
