var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: { name: "details", adaptive: true },
          on: { "before-close": _vm.beforeClose }
        },
        [
          _c("div", { staticClass: "bg-black border-2 border-white h-full" }, [
            _c("textarea", {
              staticClass: "bg-black border-none h-full w-full resize-none",
              domProps: { textContent: _vm._s(_vm.details) }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.showDetails()
            }
          }
        },
        [_vm._v("Details")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }