<template>
  <div v-if="tabledata" id="app" v-cloak>
    <div class="py-1">
      <div class="mb-2 text-left">
        <label for="search">Search</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <input type="text" placeholder="_" class="p-2 w-full text-white bg-black" v-model="searchQuery">
      </div>
    </div>
    <div class="py-1 mb-8">
      <div class="mb-2 text-left">
        <label for="filter">Account</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <select
          class="p-2"
          v-model="searchFor"
          style="max-width: 100%;   -webkit-appearance: none;-moz-appearance: none;text-overflow: '...';background: black;"
        >
          <option selected value="all">*</option>
          <option v-for="grams in instagrams" class="p-2" v-bind:key="grams">{{grams}}</option>
        </select>
      </div>
    </div>
    <table class="text-xs w-full">
      <thead>
        <tr style="max-height: 50px">
          <th
            v-for="(value, name) in columns"
            class="p-2"
            @click="sort(value)"
            v-bind:key="name"
          >{{name}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="hover:bg-gray-800" v-for="d in filteredData" style="max-height: 30px">
          <td class="border-2 border-white">
            <img style="height:50px;" onerror="this.style.display='none'" :src="d['img_url']" />
          </td>
          <td class="border-2 border-white p-2" v-html="d['username']"></td>
          <td class="border-2 border-white p-2" v-html="d['full_name']"></td>
          <td class="border-2 border-white p-2" v-html="d['inst_id']"></td>
          <td class="border-2 border-white p-2" v-html="d['followers']"></td>
          <td class="border-2 border-white p-2" v-html="d['biography']"></td>
          <td class="border-2 border-white p-2" v-html="d['email']"></td>
          <td class="border-2 border-white p-2" v-html="d['phone']"></td>
          <td class="border-2 border-white p-2" v-html="d['gender']"></td>
          <td class="border-2 border-white p-2" v-html="d['race']"></td>
          <td class="border-2 border-white p-2" v-html="d['is_private']"></td>
          <td class="border-2 border-white p-2" v-html="d['is_verified']"></td>
          <td class="border-2 border-white p-2" v-html="d['query']"></td>
        </tr>
      </tbody>
    </table>
    <p>
            <button @click="firstPage">First</button>
      <button @click="prevPage" :disabled="cantGoBack"><</button>
      <button @click="nextPage" :disabled="cantGoForward">></button>
      <button @click="lastPage">Last</button>
    </p>
    Page {{tabledata.current_page}} of {{tabledata.last_page}}. Total: {{tabledata.total}}
  </div>
</template>

<script>
export default {
  name: "InstagramFollowers",
  data() {
    return {
      searchQuery: "",
      searchFor: "all",
      currentSort: "username",
      currentSortDir: "asc",
      pageSize: 20,
      currentPage: 1
    };
  },
  methods: {
    sort: function(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      } else this.currentSortDir = "asc";
      this.currentSort = s;
    },
    firstPage: function() {
      this.currentPage = 1;
    },
    lastPage: function() {
      this.currentPage = this.tabledata.last_page;
    },
    nextPage: function() {
      this.currentPage++;
    },
    prevPage: function() {
      if (this.currentPage > 1) this.currentPage--;
    }
  },
  asyncComputed: {
    async tabledata() {
      let data = await fetch(
        `/admin/api/instagram?page=${this.currentPage}&per_page=${this.pageSize}&type=data&insturl=${this.searchFor}`
      );
      let result = await data.json();
      return result;
    },
    async columns() {
      //let data = await fetch(`/client/shopifycustomersapi?type=customers`);
      //let result = await data.json();
      //profileUrl  username  fullName  imgUrl  id  isPrivate isVerified  followedByViewer  query email
      return {
        Image: "Image",
        Username: "Username",
        Name:  "Name",
        ID: "ID",
        Followers: "Followers",
        Biography: "Biography",
        Email: "Email",
        Phone: "Phone",
        Gender: "Gender",
        Race: "Race",
        Private: "Private",
        Verified: "Verified",
        Query: "Query"
      };
    },
    async instagrams() {
      let data = await fetch(`/admin/api/instagram?type=account`);
      let result = await data.json();
      return result.sort();
    }
  },

  computed: {
    cantGoBack() {
      return this.currentPage === 1;
    },
    cantGoForward() {
      return this.currentPage === this.tabledata.last_page;
    },
    sortStr() {
      let s = "";
      if (this.currentSortDir === "desc") s += "-";
      return s + this.currentSort;
    },
    filteredData() {
      return this.tabledata.data.filter(row => {
        const searchColumns = [
          'img_url',
          'username',
          'full_name',
          'inst_id',
          'followers',
          'biography',
          'relationship',
          'email',
          'phone',
          'gender',
          'race',
          'is_private',
          'is_verified',
          'query'
        ];
        return searchColumns.some(column => {
          return row[column] && row[column].toString().toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      }).sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  }
};
</script>
