import { render, staticRenderFns } from "./InstagramFollowers.vue?vue&type=template&id=b17f16a4&"
import script from "./InstagramFollowers.vue?vue&type=script&lang=js&"
export * from "./InstagramFollowers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/number5.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b17f16a4')) {
      api.createRecord('b17f16a4', component.options)
    } else {
      api.reload('b17f16a4', component.options)
    }
    module.hot.accept("./InstagramFollowers.vue?vue&type=template&id=b17f16a4&", function () {
      api.rerender('b17f16a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/InstagramFollowers.vue"
export default component.exports