<template>
  <div v-if="tabledata" id="app" v-cloak>
    <div class="py-1">
      <div class="mb-2 text-left">
        <label for="search">Search</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <input
          class="p-2 w-full text-white bg-black"
          type="text"
          v-model="searchQuery"
          placeholder="_"
        />
      </div>
    </div>
    <div class="py-1 mb-8">
      <div class="mb-2 text-left">
        <label for="filter">Filter</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <select
          class="p-2"
          v-model="searchFor"
          style="max-width: 130px;   -webkit-appearance: none;-moz-appearance: none;text-overflow: '...';background: black;"
        >
          <option selected value="all">*</option>
          <option value="first_name">First Name</option>
          <option value="last_name">Last Name</option>
          <option value="email">Email</option>
          <option value="address1">Address</option>
          <option value="address2">Address 2</option>
          <option value="zip">Zip</option>
          <option value="phone">Phone</option>
          <option value="country">Country</option>
          <option value="company">Company</option>
        </select>
      </div>
    </div>
    <table class="text-xs w-full">
      <thead>
        <tr>
          <th v-for="(value, name) in columns" class="p-2" @click="sort(value)">{{name}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="hover:bg-gray-800" v-for="d in tabledata.data">
          <td v-for="name in columns" class="border-2 border-white p-2" v-html="d[name]"></td>
        </tr>
      </tbody>
    </table>
    <p>
      <button @click="firstPage">First</button>
      <button @click="prevPage" :disabled="cantGoBack"><</button>
      <button @click="nextPage" :disabled="cantGoForward">></button>
      <button @click="lastPage">Last</button>
    </p>
    Page {{tabledata.current_page}} of {{tabledata.last_page}}. Total: {{tabledata.total}}
  </div>
</template>

<script>
export default {
  name: "ShopifyCustomers",
  data() {
    return {
      searchQuery: "",
      searchFor: "all",
      currentSort: "first_name",
      currentSortDir: "asc",
      pageSize: 20,
      currentPage: 1
    };
  },
  methods: {
    sort: function(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      } else this.currentSortDir = "asc";
      this.currentSort = s;
    },
    firstPage: function() {
      this.currentPage = 1;
    },
    lastPage: function() {
      this.currentPage = this.tabledata.last_page;
    },
    nextPage: function() {
      this.currentPage++;
    },
    prevPage: function() {
      if (this.currentPage > 1) this.currentPage--;
    }
  },
  asyncComputed: {
    async tabledata() {
      let data = await fetch(
        `/client/shopifycustomersapi?page=${this.currentPage}&per_page=${this.pageSize}&sort_by=${this.currentSort}&sort=${this.currentSortDir}&type=customers&search=${this.searchQuery}&search_for=${this.searchFor}`
      );
      let result = await data.json();
      return result;
    },
    async columns() {
      let data = await fetch(`/client/shopifycustomersapi?type=customers`);
      let result = await data.json();
      return result;
    }
  },
  computed: {
    cantGoBack() {
      return this.currentPage === 1;
    },
    cantGoForward() {
      return this.currentPage === this.tabledata.last_page;
    },
    sortStr() {
      let s = "";
      if (this.currentSortDir === "desc") s += "-";
      return s + this.currentSort;
    }
  }
};
</script>
