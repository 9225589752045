var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "w-full lg:hidden" }, [
      _c(
        "div",
        {
          staticClass:
            "px-4 py-4 justify-end items-center shadow lg:shadow-none lg:w-4/5 xl:w-2/3 lg:mx-auto"
        },
        [
          _c("div", { staticClass: "flex-1 flex" }),
          _vm._v(" "),
          _c("div", { staticClass: "lg:hidden" }, [
            _c("i", {
              staticClass: "fa",
              class: { "fa-bars": _vm.isClosed, "fa-times": _vm.isOpen },
              on: { click: _vm.toggle }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "p-4 lg:block lg:bg-dg-purple lg:text-white",
          class: { hidden: _vm.isClosed }
        },
        [
          _c(
            "ul",
            {
              staticClass: "text-white-600 lg:flex lg:w-4/5 xl:w-2/3 lg:m-auto"
            },
            _vm._l(_vm.links, function(link) {
              return _c("li", { staticClass: "lg:ml-4" }, [
                link.href
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "block p-2 flex items-center lg:mr-4 lg:text-white",
                        class: _vm.linkClass(link),
                        attrs: { target: link.target, href: link.href }
                      },
                      [
                        _c("div", {
                          staticClass:
                            "xs:invisible sm:invisible md:invisible lg:visible pr-2"
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-1" }, [
                          _vm._v(_vm._s(link.name))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lg:invisible" })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                link.href === ""
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "block flex items-center lg:mr-4 lg:text-white"
                      },
                      [
                        _c("br"),
                        _vm._v("\n                " + _vm._s(link.name))
                      ]
                    )
                  : _vm._e()
              ])
            }),
            0
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "my-6 hidden lg:block",
        staticStyle: { "min-width": "190px" }
      },
      _vm._l(_vm.links, function(link) {
        return _c("li", [
          link.href
            ? _c(
                "a",
                {
                  staticClass: "block py-3 px-4",
                  attrs: { target: link.target, href: link.href }
                },
                [_vm._v(_vm._s(link.name))]
              )
            : _vm._e(),
          _vm._v(" "),
          link.href === ""
            ? _c(
                "div",
                {
                  staticClass:
                    "pt-5 px-3 block flex items-center lg:mr-4 lg:text-white"
                },
                [_vm._v("\n      " + _vm._s(link.name))]
              )
            : _vm._e()
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }