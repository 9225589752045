<template>
  <div v-if="tabledata" id="app" v-cloak>
    <div class="py-1">
      <div class="mb-2 text-left">
        <label for="search">Search</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
          <div class="w-full">
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchEmail"
            v-on:keyup.enter="onEnter"
            placeholder="Email_"
          />
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchPhone"
            v-on:keyup.enter="onEnter"
            placeholder="Phone_"
          />

          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchName"
            v-on:keyup.enter="onEnter"
            placeholder="Name_"
          />
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchUsername"
            v-on:keyup.enter="onEnter"
            placeholder="Username_"
          />
          <input
            class="p-2 text-white bg-black"
            type="text"
            v-model="searchAdress"
            v-on:keyup.enter="onEnter"
            placeholder="Address_"
          />
        </div>
      </div>
    </div>

    <table class="text-xs w-full">
      <thead>
        <tr>
          <th v-for="(value, name) in columns" class="p-2" @click="sort(value)">{{name}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="hover:bg-gray-800" v-for="d in tabledata">
          <td v-for="name in columns" class="border-2 border-white p-2 darkweb">
            {{ Array.isArray(d[name]) ? d[name].join(', ') : d[name] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "DarkWeb",
  data() {
    return {
      searchQueryTxt: "",
      searchQuery: "",
      searchFor: "all",
      currentSort: "first_name",
      currentSortDir: "asc",
      pageSize: 20,
      currentPage: 1,
      searchEmail: "",
      searchPhone: "",
      searchName: "",
      searchUsername: "",
      searchAdress: ""
    };
  },
  methods: {
    sort: function(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      } else this.currentSortDir = "asc";
      this.currentSort = s;
    },
    firstPage: function() {
      this.currentPage = 1;
    },
    lastPage: function() {
      this.currentPage = 1;
    },
    nextPage: function() {
      this.currentPage++;
    },
    prevPage: function() {
      if (this.currentPage > 1) this.currentPage--;
    },
    onEnter: function() {
      console.log("test")
      this.searchQuery=""
      if (this.searchEmail) this.searchQuery += "email:("+this.searchEmail+")";
      if (this.searchPhone) this.searchQuery += "phone:("+this.searchPhone+")";
      if (this.searchName) this.searchQuery += "name:("+this.searchName+")";
      if (this.searchUsername) this.searchQuery += "username:("+this.searchUsername+")";
      if (this.searchAdress) this.searchQuery += "address:("+this.searchAdress+")";
    }
  },
  asyncComputed: {
    async tabledata() {
      let data = await fetch(
        `/admin/api/darkweb?search=${encodeURIComponent(this.searchQuery )}`
      );
      let result = await data.json();
      return result;
    },
    async columns() {
      //let data = await fetch(`/admin/shopifycustomersapi?type=customers`);
      //let result = ["name","email","phone","address","password","username","ip_address","obtained_from","hashed_password"];
      return {
        id: "id",
        name: "name",
        email: "email",
        phone: "phone",
        address: "address",
        password: "password",
        username: "username",
        ip_address: "ip_address",
        vin: "vin",
        database_name: "database_name",
        hashed_password: "hashed_password"
      };
    }
  },
  computed: {
    cantGoBack() {
      return this.currentPage === 1;
    },
    cantGoForward() {
      return this.currentPage === 1;
    },
    sortStr() {
      let s = "";
      if (this.currentSortDir === "desc") s += "-";
      return s + this.currentSort;
    }
  }
};
</script>
<style scoped>
td.darkweb {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
