<template>
  <div v-if="tabledata" id="app" v-cloak>
    <div class="py-1">
      <div class="mb-2 text-left">
        <label for="search">Search</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <input
          type="text"
          placeholder="_"
          class="p-2 w-full text-white bg-black"
          v-model="searchQuery"
        />
      </div>
    </div>
    <div class="py-1">
      <div class="mb-2 text-left">
        <label for="filter">Account</label>
      </div>
      <div class="flex">
        <div class="pr-2 py-2">&gt;</div>
        <select
          class="p-2"
          v-model="searchFor"
          style="max-width: 100%;   -webkit-appearance: none;-moz-appearance: none;text-overflow: '...';background: black;"
        >
          <option selected value="all">*</option>
          <option v-for="grams in instagrams" class="p-2" v-bind:key="grams">{{grams}}</option>
        </select>
      </div>
    </div>
    <div class="py-1 mb-8">
      <label>
        Email
        <label class="switch">
          <input type="checkbox" v-model="filterHasEmail">
          <div class="slider round"></div>
        </label>
      </label>
      <label>
        Phone
        <label class="switch">
          <input type="checkbox" v-model="filterHasPhone">
          <div class="slider round"></div>
        </label>
      </label>
    </div>
    <table class="text-xs w-full">
      <thead>
        <tr style="max-height: 50px">
          <th
            v-for="(value, name) in columns"
            class="p-2"
            @click="sort(value)"
            v-bind:key="name"
          >{{name}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="hover:bg-gray-800" v-for="d in filteredData" style="max-height: 30px">
          <td class="border-2 border-white">
            <img style="height:50px;" onerror="this.style.display='none'" :src="d['img_url']" />
          </td>
          <td class="border-2 border-white p-2" v-html="d['username']"></td>
          <td class="border-2 border-white p-2" v-html="d['full_name']"></td>
          <td class="border-2 border-white p-2" v-html="d['followers']"></td>
          <td class="border-2 border-white p-2" v-html="d['biography']"></td>
          <td class="border-2 border-white p-2" v-html="d['email']"></td>
          <td class="border-2 border-white p-2" v-html="d['phone']"></td>
          <td class="border-2 border-white p-2" v-html="d['gender']"></td>
          <td class="border-2 border-white p-2" v-html="d['race']"></td>
          <td class="border-2 border-white p-2" v-html="d['city']"></td>
          <td class="border-2 border-white p-2" v-html="d['state']"></td>
          <td class="border-2 border-white p-2" v-html="d['is_business']"></td>
          <td class="border-2 border-white p-2" v-html="d['is_verified']"></td>
          <td class="border-2 border-white p-2" v-html="d['query']"></td>
        </tr>
      </tbody>
    </table>
    <p>
      <button @click="firstPage">First</button>
      <button @click="prevPage" :disabled="cantGoBack"><</button>
      <button @click="nextPage" :disabled="cantGoForward">></button>
      <button @click="lastPage">Last</button>
    </p>
    Page {{tabledata.current_page}} of {{tabledata.last_page}}. Total: {{tabledata.total}}
  </div>
</template>


<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  vertical-align: bottom;
  margin-right: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transition: 0.4s;
  border: 1px solid #fff;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #101010;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  transform: translateX(22px);
}

.slider.round {
  border-radius: 22px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<script>
export default {
  name: "InstagramClient",
  data() {
    return {
      searchQuery: "",
      searchFor: "all",
      currentSort: "username",
      currentSortDir: "asc",
      pageSize: 20,
      currentPage: 1,
      filterHasEmail: true,
      filterHasPhone: false
    };
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "";
    },
    sort: function(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        this.currentSortDir = "asc";
      }
      this.currentSort = s;
      this.tabledata.data.sort((a, b) => {
        let modifier = this.currentSortDir === "asc" ? 1 : -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    firstPage: function() {
      this.currentPage = 1;
      this.tabledata();
    },
    lastPage: function() {
      this.currentPage = this.tabledata.last_page;
      this.tabledata();
    },
    nextPage: function() {
      if (this.currentPage < this.tabledata.last_page) {
        this.currentPage++;
        this.tabledata();
      }
    },
    prevPage: function() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.tabledata();
      }
    }
  },
      asyncComputed: {
        async tabledata() {
        let params = new URLSearchParams({
          page: this.currentPage,
          per_page: this.pageSize,
          type: "data",
          insturl: this.searchFor,
        });
        if (this.filterHasEmail) {
          params.append('email', 'true');
        }
        if (this.filterHasPhone) {
          params.append('phone', 'true');
        }
        let data = await fetch(`/client/api/instagram?${params.toString()}`);
        let result = await data.json();
        return result;
      },
    async columns() {
      return {
        Image: "img_url",
        Username: "username",
        Name: "full_name",
        Followers: "followers",
        Biography: "biography",
        Email: "email",
        Phone: "phone",
        Gender: "gender",
        Race: "race",
        City: "city",
        State: "state",
        Business: "is_business",
        Verified: "is_verified",
        Query: "query"
      };
    },
    async instagrams() {
      let data = await fetch(`/client/api/instagram?type=account`);
      let result = await data.json();
      return result.sort();
    }
  },
  computed: {
    filteredData() {
      let filtered = this.tabledata.data.filter(d => {
        let matchesQuery = Object.values(d).some(val =>
          String(val).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
        return matchesQuery;
      });
      return filtered;
    },
    cantGoBack() {
      return this.currentPage === 1;
    },
    cantGoForward() {
      return this.currentPage === this.tabledata.last_page;
    },
    sortStr() {
      let s = "";
      if (this.currentSortDir === "desc") s += "-";
      return s + this.currentSort;
    }
  },
  watch: {
    filterHasEmail: function () {
      this.tabledata();
    },
    filterHasPhone: function () {
      this.tabledata();
    }
  }
};
</script>


